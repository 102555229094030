@import 'react-toastify/dist/ReactToastify.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', sans-serif;
    line-height: 1.3;
  }
  table {
  }
  th,
  td {
    @apply border-y border-gray-200 px-2.5 py-3 text-left text-sm;
  }
  thead {
    @apply bg-neutral-100;
  }
  th {
    @apply font-semibold;
  }
  a {
    @apply text-sky-500 hover:underline;
  }
}

/* Styling the scrollbar track */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Styling the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #d9d7ffe6;
  border-radius: 10px; /* Adjust the border radius to match the track */
}

/* Styling the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Hiding scrollbar buttons */
::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

.input-container {
  max-height: 20vh; /* Adjust as needed */
  overflow-y: auto;
}

.input-container textarea {
  resize: vertical;
  min-height: 100%;
  box-sizing: border-box;
}

.text-placeholder {
  /* padding-top: 2.5em; */
  pointer-events: none; /* Prevent clicking on the placeholder */
  user-select: none; /* Prevent selecting the placeholder text */
}

/* Assuming you use TailwindCSS, you might need to adjust these classes to plain CSS if not using Tailwind */
.sidebar-expanded {
  width: 250px; /* Example expanded width */
}

.sidebar-collapsed {
  width: 55px; /* Example collapsed width */
}

.sidebar-transition {
  transition: width 0.8s ease-in-out;
}

.child-transition {
  transition: opacity 0.5s ease-in-out;
}

.big {
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
}

.small {
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
}

.heading {
  font-size: 1.125rem /* 18px */;
  line-height: 1.75rem /* 28px */;
  font-weight: 700;
}

/* loading */

.demo-container {
  width: 100%;
  margin: auto;
}

.progress-bar {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}

.wrapped-text {
  width: 100%; /* Ensures text wraps within parent container */
  overflow-wrap: break-word; /* Ensure long words break and wrap */
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-input {
  margin: 5px;
  padding: 10px;
  resize: none;

  box-sizing: border-box;
  display: flex;
  align-items: center;

  overflow-y: auto; /* Enable vertical scroll */
  line-height: 1.2em; /* Line height to calculate max-height */
}

.centered-input.center {
  display: flex;
  align-items: left;
  justify-content: center;
  text-align: left;
}

.centered-input::placeholder {
  color: #aaa;
  text-align: left;
}
