.markdown {
  font-size: 14px;
  line-height: 1.6;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #333333;
  margin: 0 auto;
  text-align: left;
  box-sizing: border-box;
}

.markdown h1,
.markdown h2,
.markdown h3 {
  font-weight: 700;
  margin: 0.5rem 0 0.5rem 0;
}

.markdown code {
  font-weight: bold;
  color: blueviolet;
}

.markdown ul,
.markdown ol {
  margin-bottom: 1rem;
}

.markdown p {
  margin-bottom: 0.5rem;
}

.markdown p:only-child {
  margin-bottom: 0;
}

.markdown a {
  color: #007bff;
  text-decoration: none;
}

.markdown a:hover {
  text-decoration: underline;
}

.markdown table {
  border-collapse: collapse;
  margin-bottom: 0.5rem;
}

.markdown th,
.markdown td {
  padding: 6px 13px;
  border: 1px solid black;
}

.markdown hr {
  margin-bottom: 0.5rem;
}
